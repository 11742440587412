import * as Sentry from "@sentry/nextjs";
import {
  AcademicCapIcon,
  ArrowPathIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import Modal from "components/modal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Conversion, recordConversion } from "utils/conversions";

export default function FinanceForm({
  open,
  setOpen,
  courseFields = { fields: [] },
}) {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);

  const financeForm = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    },
  });

  const financeSubmit = async (data) => {
    // @ts-ignore
    const courses = courseFields.fields.map((x) => {
      return { id: x.event.id, title: x.fields.title };
    });
    try {
      setError(null);

      const req = await fetch("/api/callback/", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...data,
          courses,
          type: "finance",
        }),
      });

      const res = await req.json();

      if (res && res.success) {
        setComplete((s) => true);

        try {
          recordConversion({
            send_to: Conversion.callback,
          });

          (window as any).fbq && (window as any).fbq("track", "Contact");

          (window as any).heap &&
            (window as any).heap.addUserProperties({
              ...data,
            });

          (window as any).heap &&
            (window as any).heap.track("Finance Request", {
              ...data,
            });

          // @ts-ignore
          klaviyo &&
            // @ts-ignore
            klaviyo.push([
              "identify",
              {
                $email: data.email,
                $first_name: data.first_name,
                $last_name: data.last_name,
                $phone_number: data.phone_number,
              },
            ]);

          // @ts-ignore
          klaviyo &&
            // @ts-ignore
            klaviyo.push([
              "track",
              "Finance Request",
              {
                $email: data.email,
                $first_name: data.first_name,
                $last_name: data.last_name,
                $phone_number: data.phone_number,
                $path_name: window.location.pathname,
              },
            ]);
        } catch (err) {
          console.log(err);
        }
      } else {
        setError("Error processing request, Please try again");
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      setError("Error processing request, Please try again");
    }
  };

  return (
    <Modal close={() => setOpen(false)} open={open} full>
      {complete && (
        <div className="flex flex-col items-center h-full">
          <div className="p-6 px-12 border rounded">
            <p className="mb-1 text-left font-extrabold tracking-tight text-xl px-4">
              <span>Details Received</span>
            </p>
            <p className="mt-4 px-4">
              We have sucessfully received your details and this has been passed
              on to the team. The team aim to respond within 2 hours of
              submission during business hours.
            </p>
            <div className="flex justify-end">
              <div className="w-60 px-4 mt-6">
                <button
                  onClick={() => setOpen(false)}
                  className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 transition-colors duration-100"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!complete && (
        <div className="flex flex-col h-full overflow-scroll">
          <div className="bg-white shadow">
            <div className="border border-gray-100 rounded-md mx-auto divide-y divide-gray-200 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x">
              {perks.map((perk, perkIdx) => (
                <div
                  key={perkIdx}
                  className="py-4 md:py-8 lg:w-1/3 lg:flex-none lg:py-4"
                >
                  <div className="mx-auto flex max-w-xs items-center px-4 lg:max-w-none lg:px-8">
                    <perk.icon
                      className="h-8 w-8 flex-shrink-0 text-brand-600"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex flex-auto flex-col">
                      <h3 className="font-medium text-gray-900">{perk.name}</h3>
                      <p className="text-sm text-gray-500">
                        {perk.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 md:mt-0 flex-1 w-full mx-auto lg:py-8 flex flex-col">
            <div className="shadow-slate-300 pt-4 md:pt-4 flex flex-col border rounded-md">
              <h4 className="mb-1 text-left font-extrabold tracking-tight text-xl px-4">
                Finance Your Training At The Cosmetic College
              </h4>
              <div className="flex-1 pt-2">
                <div className="px-4">
                  <p>
                    Enrol in complete training programmes whilst spreading your
                    investment costs, allowing you to focus on growing your
                    skills and career with the Cosmetic College.
                  </p>
                  <p className="mt-2">
                    Complete the finance application form to get the process
                    started. Once received one a member of our finance team will
                    be in touch to work with you to find the right training and
                    payment programme.
                  </p>
                </div>
                <div>
                  <form onSubmit={financeForm.handleSubmit(financeSubmit)}>
                    <div className="overflow-hidden shadow sm:rounded-md">
                      <div className="bg-white py-5 sm:py-6 px-4">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                              {...financeForm.register("first_name", {
                                required: true,
                              })}
                            />
                            {financeForm.formState.errors.first_name && (
                              <span className="text-red-600 text-sm">
                                First name is required
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                              {...financeForm.register("last_name", {
                                required: true,
                              })}
                            />
                            {financeForm.formState.errors.last_name && (
                              <span className="text-red-600 text-sm">
                                Last name is required
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email-address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email address
                            </label>
                            <input
                              type="text"
                              name="email-address"
                              id="email-address"
                              autoComplete="email"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                              {...financeForm.register("email", {
                                required: true,
                              })}
                            />
                            {financeForm.formState.errors.email && (
                              <span className="text-red-600 text-sm">
                                Email address is required
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="contact-number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone number
                            </label>
                            <input
                              type="text"
                              id="contact-number"
                              autoComplete="contact-number"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                              {...financeForm.register("phone_number", {
                                required: true,
                              })}
                            />
                            {financeForm.formState.errors.phone_number && (
                              <span className="text-red-600 text-sm">
                                Phone number is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex md:justify-end">
                        <div className="w-full md:w-60">
                          <button
                            type="submit"
                            className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 transition-colors duration-100"
                          >
                            Start Application
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

const perks = [
  {
    name: "Flexible payment options",
    description: "All credit types considered",
    icon: CalendarIcon,
  },
  {
    name: "Grow your skills",
    description: "0% interest available",
    icon: ArrowPathIcon,
  },
  {
    name: "Control your investment",
    description: "0-48 month terms",
    icon: AcademicCapIcon,
  },
];
